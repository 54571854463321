import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Image, Button } from 'react-bootstrap';
import alert from '../../assets/alertPopup.svg';
import styles from './TokenTransferModal.module.scss';

function TokenTransferModal({ show, data, handleClose, onConfirm, onCancel, phone, loading }) {
  return (
    <Modal show={show} onHide={handleClose} className="delPopContainer" centered>
      <Modal.Body>
        <Image className="delet_icon" src={alert} alt="delete pop Icon" />
        <p>{`You are about to transfer ${data} tokens to ${phone}`}</p>
        <div className="btn_container">
          <Button className={`${styles.resetbtn} cancl_btn`} onClick={(e) => onCancel(e)}>
            <svg width="22" height="22" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
              <path d="M7 10C6.73478 10 6.48043 10.1054 6.29289 10.2929C6.10536 10.4804 6 10.7348 6 11C6 11.2652 6.10536 11.5196 6.29289 11.7071C6.48043 11.8946 6.73478 12 7 12H15C15.2652 12 15.5196 11.8946 15.7071 11.7071C15.8946 11.5196 16 11.2652 16 11C16 10.7348 15.8946 10.4804 15.7071 10.2929C15.5196 10.1054 15.2652 10 15 10H7Z" />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22 11C22 17.075 17.075 22 11 22C4.925 22 0 17.075 0 11C0 4.925 4.925 0 11 0C17.075 0 22 4.925 22 11ZM20 11C20 12.1819 19.7672 13.3522 19.3149 14.4442C18.8626 15.5361 18.1997 16.5282 17.364 17.364C16.5282 18.1997 15.5361 18.8626 14.4442 19.3149C13.3522 19.7672 12.1819 20 11 20C9.8181 20 8.64778 19.7672 7.55585 19.3149C6.46392 18.8626 5.47177 18.1997 4.63604 17.364C3.80031 16.5282 3.13738 15.5361 2.68508 14.4442C2.23279 13.3522 2 12.1819 2 11C2 8.61305 2.94821 6.32387 4.63604 4.63604C6.32387 2.94821 8.61305 2 11 2C13.3869 2 15.6761 2.94821 17.364 4.63604C19.0518 6.32387 20 8.61305 20 11Z"
              />
            </svg>
            Cancel
          </Button>
          <Button
            className={styles.deleteBtn}
            style={{ backgroundColor: '#131F43' }}
            onClick={onConfirm}
            disabled={loading}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 24C5.38331 24 0 18.6167 0 12C0 5.38331 5.38331 0 12 0C18.6167 0 24 5.38331 24 12C24 18.6167 18.6167 24 12 24ZM12 1.5C6.21019 1.5 1.5 6.21019 1.5 12C1.5 17.7898 6.21019 22.5 12 22.5C17.7898 22.5 22.5 17.7898 22.5 12C22.5 6.21019 17.7898 1.5 12 1.5ZM10.2802 16.2803L17.7803 8.78025C18.0733 8.48719 18.0733 8.01262 17.7803 7.71975C17.4872 7.42688 17.0126 7.42669 16.7197 7.71975L9.75 14.6895L7.28025 12.2197C6.98719 11.9267 6.51263 11.9267 6.21975 12.2197C5.92688 12.5128 5.92669 12.9874 6.21975 13.2803L9.21975 16.2803C9.36619 16.4267 9.55819 16.5 9.75 16.5C9.94181 16.5 10.1338 16.4267 10.2802 16.2803Z"
                fill="white"
              />
            </svg>
            {loading ? 'Please wait...' : 'Confirm'}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

TokenTransferModal.propTypes = {
  show: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  data: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  phone: PropTypes.string,
};

TokenTransferModal.defaultProps = {
  data: '',
  phone: '',
  loading: false,
};

export default TokenTransferModal;
