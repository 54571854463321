/* eslint-disable react/function-component-definition */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import axios from 'axios';
import React, { useContext, useEffect, useState, useRef } from 'react';
import Skeleton from 'react-loading-skeleton';
import { object } from 'yup';
import { Label } from 'reactstrap';
import { yupResolver } from '@hookform/resolvers/yup';
import { Col, Image, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import PropTypes, { bool } from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { SpinnerDotted } from 'spinners-react';
import { useForm } from 'react-hook-form';
import { TableContext } from '../../contexts/tableContext';
import ToggleButtonOnOff from '../ToggleButton/ToggleButton';
import styles from './CustomTable.module.scss';
import { getCookies } from '../../hooks/useCookies';
import searchImg from '../../assets/search.svg';
import Paginations from '../Pagination/Paginations';
import { DateFormatter } from '../../utilityFunctions/utilsFunctions';
import PhoneNumberInput from '../PhoneNumberBox/PhoneNumberBox';
import { Entry } from '../../redux/entrySlice';
import TokenTransferModal from '../TokenTransfer/TokenTransferModal';
import NumberInput from '../NumberBox/NumberBox';
import tokenTransferValidations from '../../pages/token-transfer/validations';
import 'react-loading-skeleton/dist/skeleton.css';
import noresults from '../../assets/noresults.svg';

const TransferTable = React.memo(
  ({ urlParam, viewPopUp, toggleButton, section, formatter, deleteCondition, dashTitle }) => {
    let url;
    const { dashboardStyle } = useContext(TableContext);
    const navigate = useNavigate();
    const { bodyStyle } = useContext(TableContext);
    const [addButtonPermissions, setAddButtonPermissions] = useState({});
    const { bodyheader } = useContext(TableContext);
    const { setDashboardHeader, formthemeStyle, errormsgStyle } = useContext(TableContext);
    const { searchboxStyle } = useContext(TableContext);
    const { tableheadStyle } = useContext(TableContext);
    const { tablebodyStyle } = useContext(TableContext);
    const [tableData, setTableData] = useState([]);
    const dispatch = useDispatch();
    const { pageNumView, setPageNumView } = useContext(TableContext);
    const [pageNum, setPageNum] = useState(1);
    const [userDetails, setUserDetails] = useState(null);
    const [showDropdown, setShowDropdown] = useState(false);
    // new
    const tablelength = tableData.length;
    const [checkedState, setCheckedState] = useState(new Array(tablelength).fill(false));
    const [checkedAll, setCheckedAll] = useState(false);
    const token = `Bearer ${getCookies('Token')}`;
    const [sorting, setSorting] = useState([{ col: 'none', sortType: 'none' }]);
    const [paginationData, setPaginationData] = useState([
      { page: 0, totalResult: 0, totalPages: 0 },
    ]);
    const [search, setSearchTerm] = useState(null);
    const [timer, setTimer] = useState(null);
    const [columns, setColumns] = useState([]);
    const [tableConfig, setTableConfig] = useState();
    const [deletedData, setDeletedData] = useState(null);
    const [selected, setselected] = useState([]);
    const [phone, setphone] = useState();
    const [tokens, settokens] = useState();
    const [tableLoading, setTableLoading] = useState(true);
    const [deleteSuccess, setDeleteSuccess] = useState();
    const [showTokenModal, setTokenModal] = useState(false);
    const [formData, setFormdata] = useState();
    const [activeEvent, setActiveEvent] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [tokenSubmitting, setTokenSubmitting] = useState(false);
    const dropdownRef = useRef(null);
    const [dataId, setDataId] = useState('');
    const [error, setError] = useState('');
    const [perror, setPError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const dataPerPage = 10;
    let response;
    let statusCode;

    const formSchema = object().shape({
      phone: tokenTransferValidations.phone,
      tokens: tokenTransferValidations.tokens,
    });

    const {
      control,
      register,
      handleSubmit,
      reset,
      trigger,
      getValues,
      formState: { errors },
    } = useForm({ resolver: yupResolver(formSchema) });

    const handleOnChange = (id, position) => {
      // eslint-disable-next-line max-len
      // eslint-disable-next-line max-len
      const updatedCheckedState = checkedState.map((item, indexed) =>
        indexed === position ? !item : item
      );
      setCheckedState(updatedCheckedState);
      setselected((oldArray) => [...oldArray, id]);
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i <= checkedState.length; i++) {
        if (checkedState[position] === true) {
          // eslint-disable-next-line no-plusplus
          for (let j = 0; j < selected.length; j++) {
            if (selected[j] === id) {
              selected.splice(j, 1);
            }
            // const newselected = selected.filter((item) => item === id);
            // setselected(newselected);
          }
        }
      }
    };

    // const activeEvent = {
    //   image: 'https://via.placeholder.com/150', // Replace with actual event image URL
    //   title: 'Sample Event Title',
    //   date: 'August 20, 2024',
    //   description:
    //     'This is a brief description of the active event. It provides key details about the event and what to expect.',
    // };

    function formatPhoneNumber(number) {
      let converted;
      if (number.startsWith('+1')) {
        const phoneNumber = number.replace(/\+1|\D/g, '');
        converted = `+1 (${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(
          6,
          10
        )}`;
      } else if (number.startsWith('+91')) {
        const phoneNumber = number.replace(/\+91|\D/g, '');
        converted = `+91 ${phoneNumber.slice(0, 5)}-${phoneNumber.slice(5)}`;
      } else {
        converted = number;
      }
      return converted;
    }

    const actionAdd = () => {
      navigate(`/${section.toLowerCase()}/create/`);
    };

    const normalizePhoneNumber = (number) => {
      let normalizedPhone = number.replace(/[\s()-]/g, '');
      if (normalizedPhone.startsWith('+')) {
        normalizedPhone = normalizedPhone.substring(1);
      }
      return normalizedPhone;
    };

    const handleOnChangeAll = () => {
      setCheckedAll(!checkedAll);
      const newarray = [];
      if (checkedAll === false) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < tableData.length; i++) {
          newarray.push(tableData[i].id);
          checkedState[i] = true;
        }
        setselected(newarray);
      } else {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < tableData.length; i++) {
          checkedState[i] = false;
        }
        setselected([]);
      }
    };

    function onSubmitdata(data) {
      setSubmitting(false);
      setError(null);
      settokens(data.tokens);
      setphone(data.phone);
      if (data.phone.trim() !== '' && data.tokens.trim() !== '') {
        setTokenModal(true);
      }
      const apiData = {
        phone: data.phone,
        tokens: data.tokens,
      };
      data.actionUrl = 'transfer';
      data.actionMethod = 'post';
      data.apiData = apiData;
      setFormdata(data);
    }

    const getUsers = async () => {
      setTableLoading(true);
      url = urlParam;
      url += `page=${pageNumView > 1 ? pageNumView : pageNum}&limit=${dataPerPage}`;
      if (sorting.sortType && sorting.sortType !== 'none') {
        url += `&sortBy=${sorting.col}:${sorting.sortType}`;
      }
      if (search !== null) {
        url += `&searchBy=${search}`;
      }
      axios
        .get(url, {
          method: 'GET',
          headers: { Authorization: token },
        })
        .then((resp) => {
          setTableLoading(false);
          statusCode = resp.status;
          return resp;
        })
        .then((res) => {
          if (statusCode === 200) {
            response = res.data.data.result;
            setTableData(response.results);
            setActiveEvent(res.data.data.activeEvent);
            setPaginationData({
              page: response.page,
              totalResult: response.totalResults,
              totalPages: response.totalPages,
            });
            setPageNum(response.page);
            const { Displayfields } = response;
            if (Array.isArray(Displayfields)) {
              setColumns(Displayfields);
            } else {
              const colkeys = Object.keys(Displayfields);
              setTableConfig(Displayfields);
              setColumns(colkeys);
            }
          }
        })
        .catch((err) => {
          setTableLoading(false);
          return err;
        });
      if (pageNumView > 1) setPageNumView(1);
    };

    const confirmTransfer = () => {
      setTokenSubmitting(true);
      dispatch(Entry(formData))
        .then((resp) => {
          setTokenSubmitting(false);
          if (resp.payload.code === 200 || resp.payload.code === 201) {
            reset();
            toast.success('Tokens Transfered.');
            setDataId(`data: ${resp.payload.data.transaction.id}`);
            setTokenModal(false);
          } else if (resp.payload.code === 404 || resp.payload.code === 400) {
            toast.error(resp.payload.message);
            setTokenModal(false);
            setTokenSubmitting(false);
          } else {
            setError('Something went wrong!');
            setTokenModal(false);
            setTokenSubmitting(false);
          }
        })
        .catch((e) => toast.error('Something went wrong!!'));
    };
    const handleTransferClose = () => setTokenModal(false);

    useEffect(() => {
      setSearchTerm(null);
      setPageNum(1);
    }, []);

    useEffect(() => {
      if (dashTitle) setDashboardHeader('');
      else setDashboardHeader(section);
      const temp = section.replace(/\s+/g, '').toLowerCase();
      const permissions = getCookies('USERPERMISSION');
      permissions.forEach((val) => {
        if (val.section.toLowerCase() === temp) {
          setAddButtonPermissions({
            view: val.view ? val.view : false,
            edit: val.edit ? val.edit : false,
            create: val.create ? val.create : false,
            delete: val.delete ? val.delete : false,
            list: !!(val.view || val.edit || val.create || val.delete),
          });
        } else if (val.submenu) {
          val.submenu.forEach((element) => {
            if (element.section.toLowerCase() === temp) {
              setAddButtonPermissions({
                view: element.view ? element.view : false,
                edit: element.edit ? element.edit : false,
                create: element.create ? element.create : false,
                delete: element.delete ? element.delete : false,
                list: !!(element.view || element.edit || element.create || element.delete),
              });
            }
          });
        }
      });
      getUsers();
      const newarray = [];
      if (checkedAll) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < tableData.length; i++) {
          newarray.push(tableData[i].id);
          checkedState[i] = true;
        }
        setselected(newarray);
      } else {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < tableData.length; i++) {
          checkedState[i] = false;
        }
        setselected([]);
      }
    }, [pageNum, search, sorting, deletedData, deleteSuccess, dataId]); // add needed variables to refresh the page

    const setSort = (param) => () => {
      setPageNum(1);
      switch (sorting.sortType) {
        case 'none':
          setSorting({ col: param, sortType: 'asc' });
          break;
        case 'asc':
          setSorting({ col: param, sortType: 'desc' });
          break;
        case 'desc':
          setSorting({ col: param, sortType: 'none' });
          break;
        default:
          setSorting({ col: param, sortType: 'asc' });
          break;
      }
    };

    const onFilterTextChange = (e) => {
      const even = e;
      if (timer) {
        clearTimeout(timer);
        setTimer(null);
      }
      setTimer(
        setTimeout(() => {
          const { value } = even.target;
          if (value.length > 0) {
            setPageNum(1);
            setSearchTerm(normalizePhoneNumber(even.target.value.trim()));
          } else {
            setSearchTerm(null);
          }
        }, 1000)
      );
    };

    const fetchUserDetails = async (phoneNumber) => {
      setIsLoading(true);
      const data = {};
      const apiData = {
        phone: phoneNumber,
      };
      data.actionUrl = 'transfer/phone';
      data.actionMethod = 'post';
      data.apiData = apiData;
      if (!errors.phone) {
        dispatch(Entry(data))
          .then((resp) => {
            if (resp.payload.code === 200 || resp.payload.code === 201) {
              setUserDetails(resp.payload.data.user);
              setIsLoading(false);
            } else if (resp.payload.code === 404 || resp.payload.code === 400) {
              toast.error(resp.payload.message);
              setUserDetails();
              setIsLoading(false);
            } else {
              setError('Something went wrong!');
              setIsLoading(false);
            }
          })
          .catch((e) => {
            setIsLoading(false);
            toast.error('Something went wrong!!');
          });
      }
    };

    const handleSearchClick = async (data) => {
      console.log('check 1');
      // trigger('phone');
      const isValidPhone = await trigger('phone'); // Validate phone only
      if (isValidPhone) {
        const normalizedPhone = normalizePhoneNumber(getValues('phone'));
        setShowDropdown(true);
        fetchUserDetails(normalizedPhone);
        setPError('');
      } else {
        setShowDropdown(false);
        setPError('Invalid phone number');
      }
    };

    // useEffect to handle clicks outside the dropdown
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          setShowDropdown(false);
        }
      };
      if (showDropdown) {
        document.addEventListener('mousedown', handleClickOutside);
      } else {
        document.removeEventListener('mousedown', handleClickOutside);
      }
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [showDropdown]);

    return (
      <div className={styles[bodyStyle]}>
        {/* DELETE MODAL */}
        <TokenTransferModal
          show={showTokenModal}
          handleClose={handleTransferClose}
          onConfirm={confirmTransfer}
          phone={phone}
          data={tokens}
          onCancel={handleTransferClose}
          loading={tokenSubmitting}
        />

        {addButtonPermissions.list && (
          <div
            className={styles[dashboardStyle]}
            style={{ minHeight: '100vh' }}
            id={styles.dashboardcont2}
          >
            <p className={styles.tokentransfersty}>Token Transfer</p>
            <form className="form_wrapper" onSubmit={handleSubmit(onSubmitdata)}>
              <div className={`${styles[formthemeStyle]} form_container`} id={styles.addform}>
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-12">
                      {' '}
                      <p className={styles.tokentransferstyin}>Transfer Token (Current Event)</p>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12">
                      <PhoneNumberInput
                        className={styles.inputbox}
                        classNamedark={styles.inputbox1}
                        labelstyle={styles.formlabel}
                        label="Phone Number"
                        placeHolder="Enter Phone Number"
                        fieldName="phone"
                        register={register}
                        errors={errors}
                        isRequired
                      />
                      {showDropdown && userDetails && (
                        <div ref={dropdownRef} className={styles.dropdown}>
                          {isLoading ? (
                            // Show skeleton while loading
                            <>
                              <p>
                                <Skeleton width={120} height={20} />
                              </p>
                              <p>
                                <Skeleton width={180} height={20} />
                              </p>
                              <p>
                                <Skeleton width={150} height={20} />
                              </p>
                            </>
                          ) : (
                            userDetails && (
                              // Show actual content once loaded
                              <>
                                <p>
                                  <strong>Name:</strong> {userDetails.name}
                                </p>
                                <p>
                                  <strong>Email:</strong> {userDetails.email}
                                </p>
                                <p>
                                  <strong>Phone:</strong> {userDetails.phone}
                                </p>
                              </>
                            )
                          )}
                        </div>
                      )}
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12">
                      <span className="ser_btn search_cstm_btn">
                        <input
                          className={styles.formbtn}
                          type="button"
                          disabled={isLoading}
                          onClick={() => handleSearchClick()}
                          value={isLoading ? 'Searching..' : 'Search'}
                        />
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-md-12 col-sm-12">
                      <div className="numb_sec">
                        <NumberInput
                          className={styles.inputbox}
                          classNamedark={styles.inputbox1}
                          labelstyle={styles.formlabel}
                          label="Token Amount"
                          fieldName="tokens"
                          placeHolder="Enter Token Amount"
                          register={register}
                          errors={errors}
                          isRequired
                        />
                      </div>
                      <div className="row">
                        <span className="sub_btn updateBtn">
                          <input className={styles.formbtn} type="submit" value="Transfer Token" />
                        </span>
                      </div>
                    </div>
                  </div>
                  <br />
                </div>
                {error && (
                  <h6 className={styles[errormsgStyle]} style={{ float: 'right' }}>
                    {error}
                  </h6>
                )}
              </div>
            </form>
            <div className={styles.searchwrapsec}>
              <div className="container-fluid" id={styles.container}>
                <div className="row">
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    <p className={styles.historystyin}>History (All Events)</p>
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    <div className="table_top">
                      <div className="search_contain mb-20">
                        <Image src={searchImg} alt="search" />
                        <input
                          className={`${styles[searchboxStyle]} searchbar`}
                          type="search"
                          onChange={onFilterTextChange}
                          placeholder="Search By Phone Number"
                          // value={search}
                          readOnly={tableLoading}
                        />
                        <div
                          className={tableLoading ? 'spinner-border spinner-border-sm' : ''}
                          id={styles.spinner}
                          role="status"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/* {activeEvent ? (
      <div
        className={styles.activeEventCard}
        onClick={() => navigate(`/event/viewdetails/${activeEvent.id}`)}
      >
        <img src={activeEvent.image} alt="Event" className={styles.eventImage} />
        <div className={styles.eventDetails}>
          <Row>
            <Col lg={6}>
              <p className={styles.eventDescription}>Event Name</p>
              <h3 className={styles.eventTitle}>{activeEvent.eventName}</h3>
              <p className={styles.eventDescription}>Location</p>
              <h3 className={styles.eventTitle}>{activeEvent.location?.venue},</h3>
              <p className={styles.eventDescription}>
                {activeEvent.location?.street}, {activeEvent.location?.city}
              </p>
            </Col>
            <Col>
              <p className={styles.eventDescription}>Date & Time</p>
              <p className={styles.eventTitle}>
                {DateFormatter(activeEvent?.startDate, 'toEventDate')} -{' '}
                {DateFormatter(activeEvent?.endDate, 'toEventDate')}
              </p>
              <p className={styles.eventTitle}>{activeEvent.time}</p>
            </Col>
          </Row>
        </div>
      </div>
    ) : (
      <p>No Events</p>
    )} */}
                <div className="table-responsive" style={{ minHeight: '250px' }}>
                  <table className="table table-borderless">
                    {columns.length > 0 && (
                      <thead>
                        <tr className={styles[tableheadStyle]}>
                          {/* {addButtonPermissions.delete && (
              <th scope="col">
                <input
                  type="checkbox"
                  id="custom-checkbox"
                  // name={data.id}
                  // value={data.id}
                  checked={checkedAll}
                  onChange={() => handleOnChangeAll()}
                />
              </th>
            )} */}
                          {/* Table Heading From the display feilds view data  */}
                          {columns.map((item) => (
                            <th scope="col">
                              {' '}
                              {tableConfig
                                ? tableConfig[item.db].alias.toUpperCase()
                                : item.view.toUpperCase()}
                              {tableConfig ? (
                                tableConfig[item.db].isSort && (
                                  <button
                                    className={styles.sortbtn}
                                    type="button"
                                    aria-label="Sort button"
                                    onClick={setSort(item.db)}
                                  >
                                    {sorting.sortType === 'asc' && sorting.col === item.db ? (
                                      <i className="fa fa-arrow-up" />
                                    ) : sorting.sortType === 'desc' && sorting.col === item.db ? (
                                      <i className="fa fa-arrow-down" />
                                    ) : (
                                      <i className="fa fa-sort" />
                                    )}
                                  </button>
                                )
                              ) : (
                                <button
                                  className={styles.sortbtn}
                                  type="button"
                                  aria-label="Sort button"
                                  onClick={setSort(item.db)}
                                >
                                  {sorting.sortType === 'asc' && sorting.col === item.db ? (
                                    <i className="fa fa-arrow-up" />
                                  ) : sorting.sortType === 'desc' && sorting.col === item.db ? (
                                    <i className="fa fa-arrow-down" />
                                  ) : (
                                    <i className="fa fa-sort" />
                                  )}
                                </button>
                              )}
                            </th>
                          ))}
                          {/* <th scope="col">ACTIONS</th> */}
                        </tr>
                      </thead>
                    )}
                    {/* Table Data mapping From the display feilds db data  as it where the db feild */}
                    {Object.keys(tableData) && !tableLoading ? (
                      <tbody>
                        {tableData.map((item, index) => (
                          <tr className={styles[tablebodyStyle]}>
                            {/* {addButtonPermissions.delete && (
                      <th scope="row">
                        <div className="left-section">
                          <input
                            type="checkbox"
                            id={`custom-checkbox-${item}`}
                            name={item.id}
                            value={item.id}
                            checked={checkedState[index]}
                            onChange={() => handleOnChange(item.id, index)}
                          />
                        </div>
                      </th>
                    )} */}
                            {columns.map((ele) => (
                              <td>
                                {ele.db === toggleButton.field ? (
                                  <ToggleButtonOnOff
                                    value={item[ele.db]}
                                    id={item.id}
                                    placeholders={['Active', 'Inactive']}
                                  />
                                ) : typeof item[ele.db] === 'boolean' ? (
                                  <h6 className="order_date"> String(item[ele.db])</h6>
                                ) : formatter && Object.keys(formatter).includes(ele.db) ? (
                                  formatter[ele.db](item[ele.db])
                                ) : ele.db === 'createdAt' ? (
                                  DateFormatter(item[ele.db], 'toEventViewDate')
                                ) : ele.db === 'eventName' ? (
                                  <b>{item[ele.db]}</b>
                                ) : ele.db === 'phone' ? (
                                  formatPhoneNumber(item[ele.db])
                                ) : ele.db === 'event' ? (
                                  item[ele.db]?.eventName
                                ) : (
                                  item[ele.db]
                                )}
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    ) : (
                      <SpinnerDotted
                        style={{
                          color: '#39979d',
                          left: '50%',
                          position: 'relative',
                          textAlign: 'center',
                          top: '50%',
                        }}
                      />
                    )}
                    {tableData.length === 0 && !tableLoading ? (
                      <div className={styles.norecordsdiv}>
                        <Image src={noresults} alt="" />
                        <h6 className={styles.norecord}>No Items to Display</h6>
                      </div>
                    ) : (
                      ''
                    )}
                  </table>
                </div>
              </div>
            </div>

            {!tableLoading && tableData.length !== 0 && (
              <Paginations
                paginationData={paginationData}
                setPageNum={setPageNum}
                dataName="Transfer"
              />
            )}
          </div>
        )}
      </div>
    );
  }
);

TransferTable.propTypes = {
  urlParam: PropTypes.string,
  viewPopUp: PropTypes.bool,
  toggleButton: PropTypes.oneOfType([PropTypes.object]),
  deleteCondition: PropTypes.oneOfType([PropTypes.object]),
  formatter: PropTypes.oneOfType([PropTypes.object]),
  section: PropTypes.string,
  dashTitle: PropTypes.string,
};

TransferTable.defaultProps = {
  viewPopUp: false,
  toggleButton: { show: false, field: 'none' },
  deleteCondition: { checkCondition: false, apiUrl: 'none' },
  section: 'transfer',
  formatter: undefined,
  urlParam: null,
  dashTitle: undefined,
};

export default TransferTable;
